import { SignalableFactory, MessageableFactory } from '../../ngrx';
import { createExpandableStateFactory } from './expandable-state';

export function createExpandableStateAdapter<F extends string>(
  signalableFactory: SignalableFactory<F>,
  messageableFactory: MessageableFactory<F>
) {
  return {
    ...createExpandableStateFactory(signalableFactory, messageableFactory),
  };
}

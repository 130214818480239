import { Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { FormGroupState, setUserDefinedProperty } from 'ngrx-forms';
import { map } from 'rxjs/operators';

import { MessageableFactory, SignalableFactory } from '../../ngrx';

export function createExpandableStateFactory<F extends string>(
  signalableFactory: SignalableFactory<F>,
  messageableFactory: MessageableFactory<F>
) {
  class ToggleExpandableMessage extends messageableFactory.create<
    'Toggle Expandable',
    { propertyId: string }
  >('Toggle Expandable') {}
  class ToggleExpandableSignal extends signalableFactory.create<
    'Toggle Expandable',
    { propertyId: string }
  >('Toggle Expandable') {}
  class SetExpandExpandableMessage extends messageableFactory.create<
    'Set Expand Expandable',
    { propertyId: string; isExpanded: boolean }
  >('Set Expand Expandable') {}
  class SetExpandExpandableSignal extends signalableFactory.create<
    'Set Expand Expandable',
    { propertyId: string; isExpanded: boolean }
  >('Set Expand Expandable') {}

  function createReducer(initialState) {
    const expandableStateReducer = <T>(
      state: FormGroupState<T> = initialState,
      action: ToggleExpandableMessage | SetExpandExpandableMessage
    ) => {
      switch (action.type) {
        case ToggleExpandableMessage.TYPE:
          if (action.payload.propertyId !== state.id) return state;
          return setUserDefinedProperty(
            'isExpanded',
            !state.userDefinedProperties.isExpanded
          )(state);
        case SetExpandExpandableMessage.TYPE:
          if (action.payload.propertyId !== state.id) return state;
          return setUserDefinedProperty(
            'isExpanded',
            (action as SetExpandExpandableMessage).payload.isExpanded
          )(state);
      }
      return state;
    };
    return expandableStateReducer;
  }

  function createToggleEffect(action$: Actions<Action>) {
    return action$.pipe(
      ofType<ToggleExpandableSignal>(ToggleExpandableSignal.TYPE),
      map(
        (toggleAction: ToggleExpandableSignal) => new ToggleExpandableMessage(toggleAction.payload)
      )
    );
  }

  function createSetEffectTransform() {
    return [
      ofType<SetExpandExpandableSignal>(ToggleExpandableSignal.TYPE),
      map((action: SetExpandExpandableSignal) => new SetExpandExpandableSignal(action.payload)),
    ];
  }
  function getSignals() {
    return {
      ToggleExpandableSignal,
      SetExpandExpandableSignal,
    };
  }

  return {
    createReducer,
    createToggleEffect,
    createSetEffectTransform,
    getSignals,
  };
}
